import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  DialogActions,
  //Tooltip,
  //Chip,
  CircularProgress,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import ChipInput from "material-ui-chip-input";
import CloseIcon from "@material-ui/icons/Close";

import NetworkCall from "../../../NetworkCall";
import { AlertContext } from "../../../Contexts";

const cryptoRandomString = require("crypto-random-string");

const styles = (theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  dialogActions: {
    margin: 0,
    padding: theme.spacing(1),
  },
  button: {
    padding: "5px 10px",
    textTransform: "capitalize",
    fontWeight: "500",
  },
  butt: {
    justifyContent: "flex-end",
  },
  text: {
    paddingLeft: "8px",
  },
  icons: {},
  margin: {
    width: "100%",
    marginTop: "8px",
    marginBottom: 10,
  },
  testbig: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  Textfield2: {
    width: "100%",
    marginBottom: 8,
  },
  text1: {
    fontWeight: "bold",
    paddingLeft: "7px",
    fontSize: "1.0rem",
  },
  methodology: {
    fontSize: "0.95rem",
  },
});

/* const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
})); */

/* const Highlighter = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Tooltip title="Parameter">
        <Chip label={props.children} variant="outlined" />
      </Tooltip>
    </div>
  );
};

const decorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback) => {
      let regex = /%\d+%/g;
      const text = contentBlock.getText();
      let matchArr, start;
      while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
      }
    },
    component: Highlighter,
  },
]); */

function AlertRuleEdit(props) {
  const [state, setState] = React.useState({
    alert_rule_code: "",
    reference_id: "",
    hashtag: [],
    description: "",
    isActive: true,
    is_email: false,
    is_push: false,
    is_sms: false,
    email_body: "",
    email_subject: "",
    from_mail: [],
    email_CC: [],
    email_BCC: [],
    push_body: "",
    push_title: "",
    sms_body: "",
    error: {},
  });

  const [loading, setLoading] = React.useState(false);

  const snack = React.useContext(AlertContext);

  React.useEffect(() => {
    if (props.callFrom !== "Edit") {
      setState({
        reference_id: "",
        hashtag: [],
        description: "",
        isActive: true,
        is_email: false,
        is_push: false,
        is_sms: false,
        email_body: "",
        email_subject: "",
        from_mail: [],
        email_CC: [],
        email_BCC: [],
        push_body: "",
        push_title: "",
        sms_body: "",
        error: {},
        alert_rule_code: cryptoRandomString({
          length: 10,
          characters: "1234567890",
        }),
      });
    }
    //view on ediing message query
    if (props.callFrom === "Edit") {
      setLoading(true);
      NetworkCall(
        "/rules/get",
        "post",
        {
          alert_rule_code: props.alert_rule_code,
        },
        null,
        true
      )
        .then((response) => {
          var _ = response.data[0];
          setState({
            ..._,
            hashtag: _?.hashtag.split(","),
            error: {},
          });
        })
        .catch((err) => console.log(err));
      setLoading(false);
    }
    return () => {
      setState({
        alert_rule_code: "",
        reference_id: "",
        hashtag: [],
        description: "",
        isActive: true,
        is_email: false,
        is_push: false,
        is_sms: false,
        email_body: "",
        email_subject: "",
        from_mail: [],
        email_CC: [],
        email_BCC: [],
        push_body: "",
        push_title: "",
        sms_body: "",
        error: {},
      });
    }; //eslint-disable-next-line
  }, [props.callFrom, props.alert_rule_code]);

  var handleChange = (event, name) => {
    setState({
      ...state,
      [name]: event.target.checked,
    });
    if (name === "is_push" && !event.target.checked) {
      setState({
        ...state,
        [name]: event.target.checked,
        push_body: "",
        push_title: "",
      });
    }
    if (name === "is_email" && !event.target.checked) {
      setState({
        ...state,
        [name]: event.target.checked,
        email_body: "",
        email_subject: "",
        from_mail: [],
        email_CC: [],
        email_BCC: [],
      });
    }
    if (name === "is_sms" && !event.target.checked) {
      setState({
        ...state,
        [name]: event.target.checked,
        sms_body: "",
      });
    }
  };

  var handletextchange = (event, name) => {
    let error = state.error;
    if (event.target.value.length === 0 && !name.includes("hashtag")) {
      error[name] = `${name} cannot be empty!`;
    } else {
      error[name] = false;
    }
    setState({
      ...state,
      [name]: event.target.value,
      error: error,
    });
  };

  var validate_reference_id = () => {
    if (props.callFrom !== "Edit")
      NetworkCall(
        "/rules/validate_referenceid",
        "post",
        {
          reference_id: state.reference_id,
        },
        null,
        true
      )
        .then((response) => {
          if (response.data.statusCode === 500) {
            setState({
              ...state,
              error: {
                reference_id: response.data.message,
              },
            });
          } else {
            setState({
              ...state,
              error: {
                ...state.error,
                reference_id: false,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  var submitvendor = async (e) => {
    e.preventDefault();
    let _ = state;
    NetworkCall(
      "/rules/upsert",
      "post",
      {
        ..._,
        hashtag: _.hashtag.join(","),
        profileId: window.sessionStorage.getItem("profileId"),
      },
      null,
      true
    )
      .then((response) => {
        snack.setSnack({
          open: true,
          severity: "success",
          msg: response.data.message,
        });
        props.handleCloseModel();
        props.load_data({ offset: 0, limit: 10 });
      })
      .catch((err) => {
        //console.log(err);
        snack.setSnack({
          open: true,
          severity: "error",
          msg: "Some error occured, Please try again!",
        });
      });
  };

  var addHashtag = (tag) => {
    setState({
      ...state,
      hashtag: [...state.hashtag, `#${tag}`],
    });
  };

  var removeHashtag = (tag) => {
    let hashtags = state.hashtag;
    hashtags.splice(state.hashtag.indexOf(tag));
    setState({
      ...state,
      hashtag: hashtags,
    });
  };
  var addFromMail = (tag) => {
    let frommail = emailRegex.test(tag);
    if (frommail) {
      setState({
        ...state,
        from_mail: [...state.from_mail, `${tag}`],
      });
      return true;
    } else {
      snack.setSnack({
        open: true,
        severity: "error",
        msg: " Please enter a valid email!",
      });
      return false;
    }
  };
  var removeFromMail = (tag) => {
    let from_mails = state.from_mail;
    from_mails.splice(state.from_mail.indexOf(tag));
    setState({
      ...state,
      from_mail: from_mails,
    });
  };
  var addEmailCC = (tag) => {
    let cc = emailRegex.test(tag);
    if (cc) {
      setState({
        ...state,
        email_CC: [...state.email_CC, `${tag}`],
      });
      return true;
    } else {
      snack.setSnack({
        open: true,
        severity: "error",
        msg: " Please enter a valid email!",
      });
      return false;
    }
  };
  var removeEmailCC = (tag) => {
    let email_CCs = state.email_CC;
    email_CCs.splice(state.email_CC.indexOf(tag));
    setState({
      ...state,
      email_CC: email_CCs,
    });
  };
  var addEmailBCC = (tag) => {
    let bcc = emailRegex.test(tag);
    if (bcc) {
      setState({
        ...state,
        email_BCC: [...state.email_BCC, `${tag}`],
      });

      return true;
    } else {
      snack.setSnack({
        open: true,
        severity: "error",
        msg: " Please enter a valid email!",
      });
      return false;
    }
  };
  var removeEmailBCC = (tag) => {
    let email_BCCs = state.email_BCC;
    email_BCCs.splice(state.email_BCC.indexOf(tag));
    setState({
      ...state,
      email_BCC: email_BCCs,
    });
  };

  const emailRegex = /\S+@\S+\.\S+/;

  const { classes } = props;
  const is_email = (
    <Grid item xs={12}>
      <Typography>Email Details</Typography>
      <TextField
        inputProps={{
          maxlength: 15,
        }} // eslint-disable-next-line
        inputProps={{
          className: classes.methodology,
        }}
        margin="dense"
        className={classes.margin}
        fullWidth
        variant="outlined"
        label="Email Subject"
        placeholder="Email Subject"
        name={"email_subject"}
        value={state.email_subject || ""}
        onChange={(e) => {
          handletextchange(e, "email_subject");
        }}
        error={state?.error?.email_subject ? true : false}
        helperText={state?.error?.email_subject ?? ""}
      />
      <TextField
        inputProps={{
          maxlength: 15,
        }} // eslint-disable-next-line
        inputProps={{
          className: classes.methodology,
        }}
        margin="dense"
        className={classes.margin}
        fullWidth
        variant="outlined"
        label="Email Body"
        placeholder="Email Body"
        name={"email_body"}
        value={state.email_body || ""}
        onChange={(e) => {
          handletextchange(e, "email_body");
        }}
        rows={6}
        multiline={true}
        error={state?.error?.email_body ? true : false}
        helperText={state?.error?.email_body ?? ""}
      />
      <ChipInput
        margin="dense"
        label="From Mail "
        type="email"
        fullWidth
        fullWidthInput
        newChipKeys={["Enter", "Tab", " "]}
        variant="outlined"
        value={state.from_mail}
        onBeforeAdd={(chip) => addFromMail(chip)}
        onDelete={(chip) => removeFromMail(chip)}
      />
      <ChipInput
        margin="dense"
        label="Email CC"
        type="email"
        fullWidth
        fullWidthInput
        newChipKeys={["Enter", "Tab", " "]}
        variant="outlined"
        value={state.email_CC}
        onBeforeAdd={(chip) => addEmailCC(chip)}
        onDelete={(chip) => removeEmailCC(chip)}
      />

      <ChipInput
        margin="dense"
        label="Email BCC"
        type="email"
        fullWidth
        fullWidthInput
        newChipKeys={["Enter", "Tab", " "]}
        variant="outlined"
        value={state.email_BCC}
        onBeforeAdd={(chip) => addEmailBCC(chip)}
        onDelete={(chip) => removeEmailBCC(chip)}
      />
    </Grid>
  );
  const is_sms = (
    <Grid item xs={12}>
      <Typography>SMS Details</Typography>
      <TextField
        inputProps={{
          maxlength: 15,
          className: classes.methodology,
        }}
        margin="dense"
        className={classes.margin}
        fullWidth
        variant="outlined"
        label="Sms Body"
        placeholder="Sms Body"
        name={"email_body" || ""}
        value={state.sms_body}
        onChange={(e) => {
          handletextchange(e, "sms_body");
        }}
        rows={6}
        multiline={true}
        error={state?.error?.sms_body ? true : false}
        helperText={state?.error?.sms_body ?? ""}
      />
    </Grid>
  );
  const is_push = (
    <Grid item xs={12}>
      <Typography>Push Notification Details</Typography>
      <TextField
        inputProps={{
          maxlength: 15,
        }} // eslint-disable-next-line
        inputProps={{
          className: classes.methodology,
        }}
        margin="dense"
        className={classes.margin}
        fullWidth
        variant="outlined"
        label="Push Title"
        placeholder="Push Title"
        name={"push_title"}
        value={state.push_title || ""}
        onChange={(e) => {
          handletextchange(e, "push_title");
        }}
        error={state?.error?.push_title ? true : false}
        helperText={state?.error?.push_title ?? ""}
      />
      <TextField
        inputProps={{
          maxlength: 15,
        }} // eslint-disable-next-line
        inputProps={{
          className: classes.methodology,
        }}
        margin="dense"
        className={classes.margin}
        fullWidth
        variant="outlined"
        label="Push Body"
        placeholder="Push Body"
        name={"push_body"}
        value={state.push_body || ""}
        onChange={(e) => {
          handletextchange(e, "push_body");
        }}
        rows={6}
        multiline={true}
        error={state?.error?.push_body ? true : false}
        helperText={state?.error?.push_body ?? ""}
      />
    </Grid>
  );

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleCloseModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          disableTypography
          id="alert-dialog-title"
          className={classes.dialogTitle}
        >
          <Typography variant="h6">{props?.callFrom} Alert Rule</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.handleCloseModel}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Grid className={classes.root}>
            {loading && <CircularProgress />}
            {/* <form onSubmit={e => submitvendor(e)}> */}
            {!loading && (
              <Grid container className={classes.testbig}>
                <Grid container item xs={12}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.isActive}
                          onChange={(e) => handleChange(e, "isActive")}
                          value={state.isActive}
                          color="primary"
                        />
                      }
                      label="IsActive"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.is_push}
                          onChange={(e) => handleChange(e, "is_push")}
                          value={state.is_push}
                          color="primary"
                        />
                      }
                      label="Push"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.is_email}
                          onChange={(e) => handleChange(e, "is_email")}
                          value={state.is_email}
                          color="primary"
                        />
                      }
                      label="E-Mail"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.is_sms}
                          onChange={(e) => handleChange(e, "is_sms")}
                          value={state.is_sms}
                          color="primary"
                        />
                      }
                      label="SMS"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputProps={{
                      maxlength: 15,
                    }} // eslint-disable-next-line
                    inputProps={{
                      className: classes.methodology,
                    }}
                    margin="dense"
                    className={classes.margin}
                    label="Alert Rule Code"
                    variant="outlined"
                    required
                    disabled
                    name="alert_rule_code"
                    value={state.alert_rule_code}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    className={classes.margin}
                    label="Reference ID"
                    variant="outlined"
                    required
                    inputProps={{
                      className: classes.methodology,
                    }}
                    onBlur={validate_reference_id}
                    onChange={(e) => handletextchange(e, "reference_id")}
                    value={state.reference_id || ""}
                    name="reference_id"
                    error={state?.error?.reference_id ? true : false}
                    helperText={state?.error?.reference_id ?? ""}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ChipInput
                    margin="dense"
                    label="Hashtags"
                    fullWidth
                    fullWidthInput
                    newChipKeys={["Enter", "Tab", " "]}
                    variant="outlined"
                    value={state.hashtag}
                    onAdd={(chip) => addHashtag(chip)}
                    onDelete={(chip) => removeHashtag(chip)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    className={classes.margin}
                    label="Description"
                    variant="outlined"
                    required
                    inputProps={{
                      className: classes.methodology,
                    }}
                    onChange={(e) => handletextchange(e, "description")}
                    value={state.description || ""}
                    name="description"
                    rows={6}
                    multiline={true}
                    error={state?.error?.description ? true : false}
                    helperText={state?.error?.description ?? ""}
                  />
                </Grid>
                {state.is_push ? is_push : null}
                {state.is_email ? is_email : null}
                {state.is_sms ? is_sms : null}
              </Grid>
            )}
            {/* </form> */}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={(e) => props.handleCloseModel(e)}>
            <Typography className={classes.cancelBtn}>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={(e) => submitvendor(e)}
          >
            <Typography>Submit</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default withStyles(styles, { withTheme: true })(AlertRuleEdit);
