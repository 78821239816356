import React from "react";
import {
  makeStyles,
  Typography,
  Divider,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
} from "@material-ui/core";
// import axios from "axios";
import { green, red } from "@material-ui/core/colors";
import NetworkCall from "../../NetworkCall";

function createData(name, type, description) {
  return { name, type, description };
}

const rows = [
  createData("reference_id", "string", "Unique Representation of a alert"),
  createData("push_receivers", "array", "List of push notification recievers"),
  createData("push_title", "array", "List of data parameters for push title"),
  createData("push_body", "array", "List of data parameters for push body"),
  createData("to_mobiles", "array", "List of mobile numbers to send SMS"),
  createData("sms_body", "array", "List of data parameters for sms body"),
  createData("to_emails", "array", "List of emails to send Mail"),
  createData("email_CC", "array", "List of data parameters for email CC"),
  createData("email_BCC", "array", "List of data parameters for email BCC"),
  createData("from_mail", "string", "Sender E-mail ID"),

  createData(
    "email_subject",
    "array",
    "List of data parameters for email subject"
  ),
  createData("email_body", "array", "List of data parameters for email body"),
  createData("email_attachments", "array", "List of attachments for email"),
];

var answer4 = "{";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${200}px)`,
      marginLeft: "200px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
    // height: `calc(100vh - ${60}px)`,
    height: "100%",
    backgroundColor: "white",
    overflow: "hidden  ",
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    paddingTop:"90px",
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  header: {
    fontSize: "1.1rem",
    fontWeight: "600",
    paddingBottom: "1.1%",
  },
  table: {
    padding: "1% 0%",
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: "2%",
  },
  query: {
    //margin: theme.spacing(1),
    //padding: "1% 0",
    width: "55%",
  },
  box: {
    display: "flex",
  },
  search: {
    marginLeft: "8px",
    border: "1px solid #9b4f4f",
    borderRadius: "2px",
    borderColor: "rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  bodyInput: {
    "& .MuiInputLabel-root": {
      textTransform: "capitalize",
    },
  },
  inputRoot: {
    height: "45px",
  },
  inputInput: {
    padding: theme.spacing(1),
    height: "20px",
    transition: theme.transitions.create("width"),
    fontSize: "16px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 248,
      "&:focus": {
        width: 244,
      },
    },
  },
  table2: {
    minWidth: 650,
  },
  tablecellHead: {
    fontWeight: 600,
    color: "#636563",
    padding: "8px",
  },
  textarea: {
    resize: "horizontal",
  },
  codeArea: {
    width: "600px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
}));

function ContentApi() {
  const classes = useStyles();

  const [apiBody, setApiBody] = React.useState({
    reference_id: "",
    alert_key: "",
    push_receivers: [],
    push_title: [],
    push_body: [],
    push_data: [],
    to_mobiles: [],
    sms_body: [],
    to_emails: [],
    email_CC: [],
    email_BCC: [],
    from_mail: "",
    email_subject: [],
    email_body: [],
    email_attachments: [
      {
        content: "",
        filename: "",
        type: "",
        disposition: "",
      },
    ],
  });

  const [response, setResponse] = React.useState();

  // const tryApi = () => {
  //   console.log("apiBody", apiBody);
  //   axios
  //     .post(process.env.REACT_APP_LOCAL_ENDPOINT + "/sendmessage", apiBody)
  //     .then((res) => setResponse(res))
  //     .catch((err) => {
  //       setResponse({ message: err.message });
  //     });
  // };

  const tryApi = () => {
    console.log("apiBody", apiBody);
    NetworkCall("/sendmessage", "post", apiBody, null, true)
      .then((res) => setResponse(res))
      .catch((err) => {
        setResponse({ message: err.message });
      });
  };

  const handleChange = (event) => {
    var { name, value } = event.target;
    if (Array.isArray(apiBody[name])) {
      value = value.split(",");
    }
    if (name === "email_attachments") {
      value = JSON.parse(value);
    }
    setApiBody({
      ...apiBody,
      [name]: value,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.header}>Sample</Typography>
        <Typography variant="body1">
          Resend OTP API can be used to resend the same OTP again to a defined
          mobile number, resend request can be attempted with both the voice
          call or text message OTP API can be used to resend the same OTP again
          to a defined mobile number, resend request can be attempted with both
          the voice call or text message.
        </Typography>
        <div className={classes.table}>
          <Typography
            style={{ fontWeight: 600, color: "#3DD7F0", padding: ".1%" }}
          >
            TRY OUT
          </Typography>
          <Divider></Divider>
          <div style={{ padding: "1%" }}>
            <Paper
              style={{ backgroundColor: "#ebeff3" }}
              className={classes.paper}
            >
              <div style={{ display: "flex" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="api-call">API calls</InputLabel>
                  <Select
                    labelId="api-call"
                    id="api-call"
                    label="Select"
                    placeholder="Select"
                    labelWidth={40}
                    value={10}
                    disabled
                  >
                    <MenuItem value={10}>Post</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-full-width"
                  disabled
                  style={{ marginTop: 0 }}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    process.env.REACT_APP_LOCAL_ENDPOINT + "/sendmessage"
                  }
                  variant="outlined"
                />
              </div>

              <div style={{ display: "flex", marginTop: "20px" }}>
                <div className={classes.codeArea}>
                  <Typography style={{ fontWeight: "600", color: "#299265" }}>
                    Request Body
                  </Typography>

                  <div className={classes.codeArea}>
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <code>{JSON.stringify(apiBody, null, 2)}</code>
                    </pre>
                  </div>
                </div>

                <div style={{ flexGrow: 1 }}></div>
                <div>
                  {Object.keys(apiBody).map((item, index) => (
                    <TextField
                      className={classes.bodyInput}
                      multiline
                      inputProps={{ className: classes.textarea }}
                      key={index}
                      style={{ margin: ".5%" }}
                      name={item}
                      label={item.replace("_", " ")}
                      variant="outlined"
                      value={
                        item === "email_attachments"
                          ? JSON.stringify(apiBody[item])
                          : apiBody[item]
                      }
                      onChange={handleChange}
                      size="small"
                      fullWidth
                    />
                  ))}
                </div>
              </div>

              <Button variant="contained" color="primary" onClick={tryApi}>
                Try
              </Button>

              {response && (
                <>
                  <Typography
                    style={{
                      fontWeight: "600",
                      color: "#299265",
                      marginTop: 10,
                    }}
                  >
                    Response
                  </Typography>
                  <pre
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    <code
                      style={{
                        color: response?.status === 200 ? green[500] : red[300],
                      }}
                    >
                      {JSON.stringify(response, null, 2)}
                    </code>
                  </pre>
                </>
              )}
            </Paper>
          </div>
          <div style={{ padding: "0 25px" }}>
            <TableContainer>
              <Table className={classes.table2} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      classes={{ head: classes.tablecellHead }}
                      align="left"
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      classes={{ head: classes.tablecellHead }}
                      align="left"
                    >
                      Type
                    </TableCell>
                    <TableCell
                      classes={{ head: classes.tablecellHead }}
                      align="left"
                    >
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell
                        classes={{ body: classes.tablecellHead }}
                        component="th"
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.type}</TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: "55px" }}>
              <Typography
                style={{
                  color: "#299265",
                  fontWeight: "600",
                  paddingBottom: "15px",
                }}
              >
                Sample Response
              </Typography>
              <Paper
                elevation={4}
                style={{
                  backgroundColor: "#ebeff3",
                  color: "#27A127",
                  padding: "40px",
                }}
              >
                {/* <Typography variant="body2"> */}[<br></br>
                <div style={{ paddingLeft: "20px" }}>{answer4}</div>
                <div style={{ paddingLeft: "40px" }}>
                  "Message": " 'QR8' details saved successfully"
                </div>
                <div style={{ paddingLeft: "20px" }}>{`},`}</div>
                <div style={{ paddingLeft: "20px" }}>{answer4}</div>
                <div style={{ paddingLeft: "40px" }}>
                  "Error": "Item '2' already Exists"
                </div>
                <div style={{ paddingLeft: "20px" }}>{`},`}</div>
                <div style={{ paddingLeft: "20px" }}>{answer4}</div>
                <div style={{ paddingLeft: "40px" }}>
                  "Error": "Item '3' already Exists"
                </div>
                <div style={{ paddingLeft: "20px" }}>{`},`}</div>
                <div style={{ paddingLeft: "20px" }}>{answer4}</div>
                <div style={{ paddingLeft: "40px" }}>
                  "Error": "Item '4' already Exists"
                </div>
                <div style={{ paddingLeft: "20px" }}>{`},`}</div>]
                {/* </Typography> */}
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentApi;
