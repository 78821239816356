import React from "react";

import { Grid,makeStyles } from "@material-ui/core";
import Menu from "../Common/Menu";
import SearchMessage from "../MessageCatalog/SearchMessage";

const useStyles = makeStyles((theme)=>({
  root:{
    zIndex:theme.zIndex.drawer +1
  },
  appear:{
    [theme.breakpoints.down("sm")]:{
      display:"none"
    },
    display: "flex",
    // minHeight: "100vh",
    width: "100%",
  }
}))

function Dashboard() {
  const classes = useStyles();
  return (
    <Grid container style={{ position: "fixed" }}>
      <SearchMessage
      />
      <div className={classes.appear}>
      <Grid
        item
        style={{
          display: "flex",
          width: "100%",
       
        }}
        
      >
        {/* {width > 959 && <Menu />} */}
        <Menu  />
      </Grid>
      </div>
    </Grid>
  );
}

export default Dashboard;
